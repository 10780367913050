<template>
  <div>
    <CCard accentColor="primary">
      <CCardHeader>
        <slot name="header">
          <CIcon name="cil-grid" />
          {{ title }}
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow v-show="!formVisibility">
          <CCol md="12">
            <div role="group" class="form-group">
              <label>OPD</label>
              <v-select
              v-model="form.nama_unit_kerja"
              :options="optionsOPD"
              label="nama_opd"
              :reduce="a => a.nama_opd"
              :filterable="true"
              @search="onSearchOPD"
              ></v-select>
            </div>
            <CInput
              label="No Usulan"
              placeholder="No Usulan"
              v-model="form.no_surat"
            ></CInput>
          </CCol>
        </CRow>
        <CButtonGroup size="sm">
          <CButton color="info" v-show="!formVisibility" @click="loadData()">
            <CIcon name="cil-search" />Cari
          </CButton>
          <CButton color="warning" v-show="!formVisibility" @click="reset()">
            <CIcon name="cil-loop-circular" />Reset
          </CButton>
        </CButtonGroup>
        <hr />
        <CCollapse :show="formVisibility">
          <form-input
            @done="resetTabel"
            @clicked="onClickChild"
            ref="formInput"
          />
        </CCollapse>
        <CDataTable
          :items="computedItems"
          :fields="fields"
          hover
          small
          border
          v-show="!formVisibility"
          :loading="loading"
        >
          <template #show_details="{ item }">
            <td class="py-2">
              <CButton
                color="primary"
                variant="outline"
                square
                size="sm"
                class="ml-1 mt-1"
                @click="toggleDetails(item, true)"
                >View</CButton
              >
            </td>
          </template>
        </CDataTable>
        <CPagination
          :activePage.sync="page"
          :pages.sync="totalPage"
          size="sm"
          align="end"
          v-show="!formVisibility"
        />
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
  import formInput from "./form";
  import _ from "lodash";
  import VSelect from "vue-select";
  const fields = [
    { key: "number", label: "No" },
    { key: "no_surat", label: "No. Usulan" },
    { key: "tgl_surat", label: "Tanggal Usulan" },
    { key: "nama_unit_kerja", label: "OPD" },
    { key: "jumlah_usulan", label: "Jumlah yang Diusulkan" },
    {
      key: "show_details",
      label: "Action",
      sorter: false,
      filter: false,
    },
  ];
  export default {
    name: "DisposisiSKUMPTK",
    components: {
      formInput,
      VSelect
    },
    data() {
      return {
        items: [],
        fields,
        details: [],
        collapseDuration: 0,
        formVisibility: false,
        loading: false,
        page: 1,
        totalPage: 0,
        optionsInstansi: [],
        optionsOPD: [],
        optionsUnitKerja: [],
        optionsJabatanUmum: [],
        form: {
          nama_unit_kerja: null,
          no_surat: null,
          jenis_layanan_general: 'skumptk',
          tracking_index_layanan: null
        },
      };
    },
    watch: {
      page: function () {
        this.loadData();
      },
    },
    computed: {
      computedItems() {
        var number = 1;
        let total_pages = 10 * (this.page - 1);
        return this.items.map((item) => {
          return {
            ...item,
            number: number++ + total_pages,
            nama_unit_kerja: item.mst_unit_kerja.nama_unit_kerja,
            jumlah_usulan: item.pelayanan_detail.length
          };
        });
      },
      title() {
        return this.formVisibility
          ? "Form Disposisi SKUMPTK"
          : "Tabel Disposisi SKUMPTK";
      },
    },
    mounted() {
      this.loadData();
    },
    methods: {
      reset() {
        this.form.nama_unit_kerja = null;
        this.form.no_surat = null;
        this.page = 1;
        this.loadData();
      },
      async loadData() {
        try {
          this.loading = true;
          let data = await this.$store.dispatch("pelayanan/indexDisposisiSkumptk", {
            page: this.page,
            data: this.form,
          });
          this.items = data.data;
          this.totalPage = data.last_page;
        } catch (ex) {
          this.items = [];
        } finally {
          this.loading = false;
        }
      },
      resetTabel() {
        this.$refs.formInput.populateInput(null);
        this.formVisibility = false;
        this.loadData();
      },
      toggleDetails(item, formDisabled) {
        this.formVisibility = true;
        this.$refs.formInput.populateInput(item, formDisabled);
        this.collapseDuration = 300;
        this.$nextTick(() => {
          this.collapseDuration = 0;
        });
      },
      openNewForm() {
        this.formVisibility = !this.formVisibility;
        this.$refs.formInput.populateInput(null);
      },
      onClickChild() {
        this.formVisibility = !this.formVisibility;
        this.$refs.formInput.populateInput(null);
      },
      async onSearchOPD(search, loading) {
        loading(true);
        await this.searchOPD(search, this);
        loading(false);
      },
      searchOPD: _.debounce(async (search, vm) => {
        let options = await vm.$store.dispatch(
          "kualifikasi_jabatan_struktural/autocomplete_opd",
          search
        );
        if (search) vm.optionsOPD = options;
      }, 300),
    },
  };
</script>